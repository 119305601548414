import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./Pages/Admin/AdminPages/Login/AuthContext";
import { HelmetProvider } from "react-helmet-async";
// import MyHelmet from "./Pages/HelmetSEO/HelmetAsync";
const App = lazy(() => import("./App"));
const root = createRoot(document.getElementById("root"));

root.render(
  <>
    <AuthProvider>
      <Suspense
        fallback={
          <h3
            style={{
              display: "flex",
              placeContent: "center",
              placeItems: "center",
              height: "100vh",
              backgroundColor: "#0B0B22",
              color: "#fff",
            }}
          >
            Hupākha Marihālo...
            {/* Navā net mamthaebamla */}
          </h3>
        }
      >
        <HelmetProvider>
          {/* <MyHelmet
            author={"Chipui"}
            description={
              "Khangkhui Phungdhar, east of Ukhrul town, is a charming village 12 kilometers away. It features the scenic Harva Khangai mountain, providing mesmerizing sunrise and sunset views. With historical significance from the Second World War, it offers a unique blend of natural beauty and historical importance."
            }
            imageUrl={
              "https://firebasestorage.googleapis.com/v0/b/khangkhuiphungdhar.appspot.com/o/file%2FKHANGKHUI%20PHUNGDHAR.jpeg%20on%2008-Dec-2021%2C%2020%3A56%3A01?alt=media&token=152b2e89-baeb-4f4f-93bf-c10468a08182"
            }
            siteName={
              "Explore Khangkhui Phungdhar's official site | Khangkhui Phungdhar | Khangkhui Khunou"
            }
            title={
              "Explore Khangkhui Phungdhar's official site | Khangkhui Phungdhar | Khangkhui Khunou"
            }
            url={"https://khangkhuiphungdhar.vercel.app/"}
          /> */}
          <App />
        </HelmetProvider>
      </Suspense>
    </AuthProvider>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
