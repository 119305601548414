import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIALSTATE = {
  isAuthenticated: false,
};

const storedAuthState = localStorage.getItem("isAuthenticated");

if (storedAuthState) {
  try {
    INITIALSTATE.isAuthenticated = JSON.parse(storedAuthState);
  } catch (e) {
    console.error("Error parsing stored auth state:", e);
  }
}
export const AuthContext = createContext(INITIALSTATE);
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIALSTATE);
  useEffect(() => {
    localStorage.setItem(
      "isAuthenticated",
      JSON.stringify(state.isAuthenticated)
    );
  }, [state.isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: state.isAuthenticated, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};
